
// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
$admin-primary: mat.define-palette(mat.$indigo-palette);
$adminAccent: mat.define-palette(mat.$blue-palette);

// The warn palette is optional (defaults to red).
$admin-warn: mat.define-palette(mat.$red-palette);
$light-primary-text : #fff;
$dark-primary-text : #1B242B;
$cgPrimary: (
  50: #017bdf,
  74: #5796DB,
  120: #ACCEEF,
  
  contrast: (
    50: $light-primary-text,
    74: $light-primary-text,
    120: $dark-primary-text,
  )
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$admin-theme: mat.define-light-theme((
  color: (
    primary: $cgPrimary,
    accent: $adminAccent,
    warn: $admin-warn,

  )
  
));

@include mat.core();
@include mat.all-component-themes($admin-theme);
@import '~@angular/material/theming';

@include mat-core();

// $app-primary: mat-palette($mat-indigo);
// $app-accent:  mat-palette($mat-pink, A200, A100, A400);

// $app-theme: mat-dark-theme($app-primary, $app-accent);

// @mixin mix-app-theme($app-theme) {
//   $primary: map-get($app-theme, primary);
//   $accent: map-get($app-theme, accent);

//   .mat-button-toggle {
//     background-color: #c9d8dd;//mat-color($primary);
//     color: #313131//mat-color($primary, default-contrast);
//   }

//   .mat-button-toggle-checked {
//     background-color: #1976D2;//mat-color($accent);
//     color: #fff//mat-color($primary, default-contrast);
//     // color: mat-color($accent, default-contrast);
//   }
// }

// // Include the mixin
// @include mix-app-theme($app-theme);
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;800&display=swap');
@import '/src/app/colors.scss';
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


html, body { height: 100%; }
body { margin: 0; padding:0;
  font-family: 'Barlow', sans-serif!important;
  background-color: #F1F3F6!important;
  height: 100vh;
  padding: 0;
  margin: 0;
  width: 100vw;
  // overflow: hidden;
}

mat-card{
  border-radius: 4px;
  -webkit-box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
    box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
}
.p0 {
  padding: 0px!important;
}
.w100{
  width: 100%;
}
.h100{
  height: 100%;
}
.h32 {
  height: 32px;
}
._tableHeader{
  // height:40px;
  background-color:#DDECF2;
  color:#737A7D;
}
.tableTR{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:72px;
  border-top:1px solid #E0E0E0;

  background-color: rgba(255,255,255,0);
  transition: all 0.35s ease-in;

}
/*.trHit{
  position:absolute;
  cursor:pointer;
  top:0;
  left:0;
  width:100%;
  height:72px;
  background-color:rgba(255,0,255,0.1);
  z-index:0;
}
.trHit:hover{
  background-color:rgba(255,0,255,0.4);
}*/

.tableTR:hover{
  background-color: rgba(224,224,224,0.45);
  transition: all 0.35s ease-out;
}
tr.mat-header-row {
  height: 34px!important;
}
.tableContainer {
  height: calc(100vh - 150px);
  overflow: hidden;
  padding: 0px!important;
}
.tableContainerSection {
  height: 100%;
  overflow: auto;
}

table {
  width: 100%;
}
.overlay-toolbar{
  position: static!important;
  height: 44px!important;
  width: 100%;
  border-radius: 6px;
}
.toolbar-spacer{
  flex: 1 1 auto;
}


.formControl {


  float: left;
  width: 100%;
  // max-width: 300px;
   display: block;
  padding: 6px 10px 6px 10px;
  // padding-top:10px;
  border: 1px dotted #cccccc;
  border-radius: 4px;
  outline: none;
  font-size: 14px;

  color:rgba($color: #444444, $alpha: 0.9);
  background: #fcfcfc;
  transition: border 0.25s ease-out;
  transition: color 0.45s ease-out;

}
.formControlDisabled{
  color:rgba($color: #444444, $alpha: 0.5)!important;
}
.formControl:focus {
  color:rgba($color: #444444, $alpha: 0.9);
  border: 1px solid #007BDF!important;
  transition: border 0.45s ease-out;
  transition: color 0.45s ease-out;
}
.formControlError{
  border: 2px solid #ff0000!important;
  transition: border 0.45s ease-out;
}
.formControlErrorAddress{
  border: 1px solid #ff0000!important;
  transition: border 0.45s ease-out;
}
.formControlError:focus{
  border: 1px solid #D32F2F!important;
  transition: border 0.45s ease-out;
}
.filterChipUnactive{
  color: #7C8B96!important;

  border: 1px solid #BFCCD5!important;
  background-color: rgba($color: #BFCCD5, $alpha: 0.0)!important;
  transition: all 0.25s ease-out!important;
}
.filter-chip{
  cursor: pointer!important;
  user-select: none;
  font-size: 12px!important;
  font-weight: 500!important;
}
.filter-chip-icon{
  cursor: pointer!important;
  width: 19px!important;
  height: 19px!important;
  font-size: 14px!important;
  font-weight: 600!important;
  //  margin-top: 6px!important;
    padding-top: 2px!important;

}

.mt5{
  margin-top: 5px!important;
}
.mt15{
  margin-top: 15px!important;
}

.textfield100{
  width: 100%;
}

.textfield50{
  width: 50%;
}

.textfield70{
  width: 70%;

}

.textfield30{
  width: 30%;
}

.textfield35{
  width: 35%;
}
.textfield20{
  width: 20%;
}
.textfield15{
  width: 15%;
}
.textfield40{
  width: 40%;
}
*:focus {
  outline: none !important;
  border: 0 !important;
}
.firstRow{
  padding-top: 20px;
}
.p0{
  padding: 0px!important;
}
.mt2{
  margin-top:2px!important;
}
.mt3{
  margin-top:3px!important;
}
.mt4{
  margin-top:4px!important;
}
.mt0{
  margin-top: 0px!important;
}
.mb0{
  margin-bottom: 0px!important;
}
.mr0{
  margin-right: 0px!important;
}
.mr2{
  margin-right: 2px!important;
}
.mr10{
  margin-right: 10px!important;
}
.mr15{
  margin-right: 15px!important;
}
.ml0{
  margin-left: 0px!important;
}
.ml2{
  margin-left: 2px!important;
}
.ml3{
  margin-left: 3px!important;
}
.ml5{
  margin-left: 5px!important;
}
.ml10{
  margin-left: 10px!important;
}
.ml13{
  margin-left: 13px!important;
}
.ml15{
  margin-left: 15px!important;
}
.ml20{
  margin-left: 20px!important;
}
.ml25{
  margin-left: 25px!important;
}
.ml30{
  margin-left: 30px!important;
}
.m_t5{
  margin-top: 5px!important;
}
.m_t8{
  margin-top: 8px!important;
}
.m_t10{
  margin-top: 10px!important;
}
.m_t12{
  margin-top: 12px!important;
}
.m_t15{
  margin-top: 15px!important;
}
.m_t19{
  margin-top: 17px!important;
}
.m_t20{
  margin-top: 20px!important;
}
.m_t30{
  margin-top: 30px!important;
}
.m_t40{
  margin-top: 40px!important;
}
.m_b20{
  margin-bottom: 20px!important;
}
.m_b15{
  margin-bottom: 15px!important;
}
.m_b10{
  margin-bottom: 10px!important;
}
.m_b40{
  margin-bottom: 40px!important;
}
.p_t0{
  padding-top: 0px!important;
}
.p_t2{
  padding-top: 2px!important;
}
.p_t3{
  padding-top: 3px!important;
}
.p_t4{
  padding-top: 4px!important;
}
.p_t5{
  padding-top: 5px!important;
}
.p_t8{
  padding-top: 8px!important;
}
.p_t10{
  padding-top: 10px!important;
}
.p_t15{
  padding-top: 15px!important;
}
.p_t20{
  padding-top: 20px!important;
}
.p_t25{
  padding-top: 25px!important;
}
.p_t30{
  padding-top: 30px!important;
}
.p_t40{
  padding-top: 40px!important;
}
.p_t60{
  padding-top: 60px!important;
}
.p_t80{
  padding-top: 80px!important;
}
.p_b60{
  padding-bottom: 60px!important;
}
.p_b60{
  padding-bottom: 60px!important;
}
.p_b40{
  padding-bottom: 40px!important;
}
.p_b20{
  padding-bottom: 20px!important;
}
.p_b15{
  padding-bottom: 15px!important;
}
.p_b10{
  padding-bottom: 10px!important;
}
.p_b5{
  padding-bottom: 5px!important;
}
.pl0{
  padding-left: 0px!important;
}
.pr0{
  padding-right: 0px!important;
}
.pt0{
  padding-top: 0px!important;
}
.pb0{
  padding-bottom: 0px!important;
}
.pb2{
  padding-bottom: 2px!important;
}
.pb8{
  padding-bottom: 8px!important;
}
.m0{
  margin: 0px!important;
}
.pr50{  padding-right: 50px!important; }
.pr40{  padding-right: 40px!important; }
.pr32{  padding-right: 32px!important; }
.pr30{  padding-right: 30px!important; }
.pr20{  padding-right: 20px!important; }
.pr25{  padding-right: 25px!important; }
.pr16{  padding-right: 16px!important; }
.pr14{  padding-right: 14px!important; }
.pr12{  padding-right: 12px; }
.pr10{  padding-right: 10px; }
.pr8{  padding-right: 8px!important; }
.pr5{  padding-right: 5px!important; }

.pl40{  padding-left: 40px!important; }
.pl32{  padding-left: 32px!important; }
.pl30{  padding-left: 30px!important; }
.pl25{  padding-left: 25px!important; }
.pl20{  padding-left: 20px!important; }
.pl20{  padding-left: 20px!important; }
.pl16{  padding-left: 16px; }
.pl14{  padding-left: 14px; }
.pl12{  padding-left: 12px!important; }
.pl10{  padding-left: 10px!important; }
.pl8{  padding-left: 8px!important; }
.pl5{  padding-left: 5px!important; }
.p0{padding: 0px!important;}

.p30{padding: 30px!important;}
/*
open icon button
*/
.spacer20{
  height:20px;
}
.mat-table thead{
  background-color: $_blue_light;
}
.w400{
  width: 400px
}
.w15p{
  width: 15%!important;
}
.w20p{
  width: 20%!important;
}
.w25p{
  width: 25%!important;
}
.w30p{
  width: 30%!important;
}
.w33p{
  width: 33.33333333%!important;
}
.w35p{
  width: 35%!important;
}
.w40p{
  width: 40%!important;
}
.w45p{
  width: 45%;
}
.w50p{
  width: 50%;
}
.w60p{
  width: 60%;
}

.h30p{
  height: 30%!important;
}
.h48px{
  height: 48px!important;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

/* You can add global styles to this file, and also import other style files */
// @mixin mat-chips-theme($theme) {
//   $is-dark-theme: map-get($theme, is-dark);
//   $primary: map-get($theme, primary);
//   $accent: map-get($theme, accent);
//   $warn: map-get($theme, warn);
//   $background: #fff;// map-get($theme, background);
//   $foreground: map-get($theme, foreground);


//   .mat-chip.mat-standard-chip {

//     min-height: 19px;
//     padding-left: 8px!important;
//     padding-right: 8px!important;
//     padding-top: 6px!important;
//     padding-top: 6px!important;
//     border-radius: 14px!important;
//     // @include mat-chips-color($unselected-foreground, $unselected-background);

//     &:not(.mat-chip-disabled) {
//       &:active {
//         // @include _mat-theme-elevation(3, $theme);
//       }

//       .mat-chip-remove:hover {
//         opacity: 0;
//       }
//     }

//     &.mat-chip-disabled {
//       opacity: 0.4;
//     }

//     &::after {
//       background: #fff;//map_get($primary, base);
//       //background: mat-chips-theme-color($primary);
//     }
//   }

//   .mat-chip.mat-standard-chip.mat-chip-selected {
//     height: 19px!important;
//     &.mat-primary {
//       @include mat-chips-theme-color($primary);
//     }

//     &.mat-warn {
//       @include mat-chips-theme-color($warn);
//     }

//     &.mat-accent {
//       @include mat-chips-theme-color($accent);
//     }
//   }
// }

// @mixin mat-dialog-theme($admin-theme) {
//   // $background: map-get($theme, background);
//   // $foreground: map-get($theme, foreground);

//   .mat-dialog-container {
//     padding: 0px!important;
//     border-radius: 6px!important;
//     padding-left: 0px!important;
//     padding-right: 0px!important;
//     padding-top: 0px!important;
//     padding-bottom: 0px!important;
//     // @include _mat-theme-elevation(0, $theme);
//     -webkit-box-shadow: 0 15px 65px rgba(12,33,50,.1), 0 5px 45px rgba(0,0,0,.1)!important;
//     box-shadow: 0 15px 65px rgba(12,33,50,.1), 0 5px 45px rgba(0,0,0,.07)!important;
//     // background: mat-color($background, dialog);
//     // color: mat-color($foreground, text);
//   }
// }

.mat-dialog-container {
  padding: 0px!important;
  border-radius: 6px!important;
  padding-left: 0px!important;
  padding-right: 0px!important;
  padding-top: 0px!important;
  padding-bottom: 0px!important;
  // @include _mat-theme-elevation(0, $theme);
  -webkit-box-shadow: 0 15px 65px rgba(12,33,50,.1), 0 5px 45px rgba(0,0,0,.1)!important;
  box-shadow: 0 15px 65px rgba(12,33,50,.1), 0 5px 45px rgba(0,0,0,.07)!important;
  // background: mat-color($background, dialog);
  // color: mat-color($foreground, text);
}

.mediumIconButton{
  cursor: pointer;
  user-select: none;
  color:#ABBBC7;
  transition: all 0.45s ease-out;
}
.mediumIconButton:hover {
  color: $_blue;
  transition: all 0.45s ease-out;
}
.select-form-field{
  font-size: 14px!important;
  font-weight: 500!important;
  line-height: 0.9!important;
  padding: 0px!important;
  margin: 0px!important;
}



.dxfx_icon_btn_mini{
  width: 18px!important;
  height: 18px!important;
  font-size: 18px!important;
  color:#ABBBC7!important;
  transition: color 0.35s ease-in-out;
  cursor: pointer;
}

.dxfx_icon_btn_mini:hover{
  color:$_blue!important;
  transition: color 0.35s ease-in-out;
}

.w100{
  width: 100%;
}

.nav-icon-mini{
  -webkit-user-select:none!important;
  -moz-user-select: none;
  -webkit-touch-callout:none;
  padding-top: 5px;
  cursor: pointer;
  font-size: 15px!important;

}
.nav-icon-mini-disabled{
  user-select: none;
  color: $_inactive_button_text!important;
}
.mat-list-base .mat-list-item, .mat-list-base .mat-list-option {

  height: 32px!important;




}
.mat-list-base .mat-list-item .mat-list-item-content-reverse, .mat-list-base .mat-list-option .mat-list-item-content-reverse {

  border-bottom: 1px solid rgba(0,0,0,0.2)!important;
  font-size: 12px!important;
  // padding: 0 4px 0 0px!important;
}
.mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // box-sizing: border-box;
  // padding: 0 16px;
  // position: relative;
  // height: inherit;
  height: 32px!important;
  border-bottom: 1px solid rgba(0,0,0,0.2)!important;
  font-size: 12px!important;
  padding: 0px!important;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #94C1CA!important;
}

.mat-standard-chip {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;
  padding: 0px 12px!important;
  border-radius: 14px!important;
  align-items: center;
  cursor: default;
  min-height: 19px!important;
  height: 1px;
  padding-bottom: 2px!important
}



.formControl {
  font-family: 'Barlow'!important;

  float: left;
  width: 100%;
  // max-width: 300px;
   display: block;
  padding: 6px 10px 6px 10px;
  // padding-top:10px;
  border: 1px dotted #cccccc;
  border-radius: 4px;
  outline: none;
  font-size: 14px;

  color:rgba($color: #2b2a2a, $alpha: 0.9);
  background: #fcfcfc;
  transition: border 0.25s ease-out;
  transition: color 0.45s ease-out;

}
.formControlDisabled{
  color:rgba($color: #444444, $alpha: 0.5)!important;
}
.formControl:focus {
  color:rgba($color: #2b2a2a, $alpha: 0.9);
  border: 1px solid #007BDF!important;
  transition: border 0.45s ease-out;
  transition: color 0.45s ease-out;
}
.formControlError{
  border: 2px solid #ff0000!important;
  transition: border 0.45s ease-out;
}
.formControlErrorAddress{
  border: 1px solid #ff0000!important;
  transition: border 0.45s ease-out;
}
.formControlError:focus{
  border: 1px solid #D32F2F!important;
  transition: border 0.45s ease-out;
}

._br {
  border: 1px solid deeppink
}
mat-label{
  font-size: 14px!important;
  font-weight: 400!important;
  margin-bottom: 6px!important;
}

.mat-caption{
  font-size: 10px!important;
  opacity: 0.8!important;
}

.overlay-toolbar, .overlay-toolbar h1, .overlay-toolbar h2, .overlay-toolbar h3, .overlay-toolbar h4, .overlay-toolbar h5, .overlay-toolbar h6 {
  font: 500 16px Barlow!important;
  letter-spacing: normal!important;
  margin: 0!important;
}
h2, h3, h4 {
  font-size: 16px;
  font-family: "Barlow";
}
.dxfx_icon_btn_medium{
  // transform: rotateZ(0deg);
  width: 22px!important;
  height: 22px!important;
  font-size: 22px!important;
  color:#ABBBC7!important;
  transition: color 0.35s ease-in-out;
  cursor: pointer;
}

.dxfx_icon_btn_medium:hover{
  // transform: rotateZ(270deg);
  color:$_blue!important;
  transition: color 0.35s ease-in-out;
}
.dxfx_icon_btn_medium_disabled{
  width: 22px!important;
  height: 22px!important;
  font-size: 22px!important;
  // color:#ABBBC7!important;
  color:$_inactive_button_text!important;

  pointer-events: none;
}
.dxSpinnerC {
  width: 100vw;
  height: 100vh;
}


.textEditable{
  padding: 6px 32px!important;
  border: 1px solid rgba(0,0,0,0.05);
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  background-color: rgba(0,0,0,0.01);
  transition: all 0.35s ease-in-out;
}
.textEditable:hover {
  border: 1px solid rgba(0,0,0,0.2);
  background-color: rgba(0,0,0,0.09);
  transition: all 0.35s ease-in-out;
}


h1{
  font-size: 32px;
  font-weight: 500;
}

h2{
  font-size: 24px;
  font-weight: 500;
}

h3{
  font-size: 20px;
  font-weight: 500;
}
.mat-checkbox-inner-container {
  // display: inline-block;
  // line-height: 0;
  // margin: auto;
  // margin-right: 8px;
  // order: 0;
  // position: relative;
  // vertical-align: middle;
  // white-space: nowrap;
  // flex-shrink: 0;
  height: 20px!important;
  width: 20px!important;
}


.backdrop-background {
  // filter: blur(10px)!important;
  background-color: rgba(32,32,32,0.09);
  backdrop-filter: blur(2px);
}


.baseTT{
  background: rgba(25,118,210,0.9);
  font-size: 14px!important;//$mat-tooltip-font-size;
  font-weight: 500!important;
  color: #FFF!important;
}
.baseBigTT{
  width: 500px!important;
  max-width: 500px!important;
  max-height: 400px!important;
  background: rgba(25,118,210,0.9);
  font-size: 12px!important;//$mat-tooltip-font-size;
  font-weight: 400!important;
  color: #FFF!important;
  
}
.tableFilterIcon{
  font-size:14px;
  user-select:none;
  color:#AFB3B5;
  padding-top: 4px;
  height: 24px;
  overflow: hidden;
}
.tableFilterIconActive{
  color:#737A7D;
}
.clickable{
  cursor:pointer;
  display:block;
}

.container, .container-fluid  {
  font-family: 'Barlow', sans-serif;
}

.chartBox{
  width: 660px;
  height: 290px;
  margin-right: 10px;
  -webkit-box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
      box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
}

.chartBoxBig{
  width: 1300px;
  min-height: 290px;
  margin-right: 10px;
  -webkit-box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
      box-shadow: 0 15px 35px rgba(12,33,50,.1), 0 5px 15px rgba(0,0,0,.07)!important;
}
.h2Size{
  font-size: 24px!important;
  font-weight: 500!important;
}

._bRight {
  border-right: 1px solid rgba(0,0,0,.07);
}

.fs14 {
  font-size: 14px;
  font-weight: 400;
}
.fs12 {
  font-size: 12px;
  font-weight: 300;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 28px!important; 
}

.customSnack {
  background-color: #D32F2F;
  color: white;
  font-size: 18px;
}

.router{
  // overflow: hidden;
  // position: absolute;
  width: 100%;
  bottom: 0px;
  top: 44px;
  // padding-bottom: 20px;
}
.tableFilterIcon {
  font-size: 14px;
  user-select: none;
  color: #AFB3B5;
}

.tableFilterIconActive {
  color: #737A7D;
}
.tlc {
  text-align: center;
}
.tlr {
  text-align: end;
}

.mat-checkbox-inner-container {
  margin-left: 4px;
}
.mat-checkbox-layout .mat-checkbox-label {
  line-height: 18px;
  font-size: 14px;
}

.mat-select-value-text {
  font-size: 14px;
  font-weight: 400;
}